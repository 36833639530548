<template>
    <v-app id="inspire">
        <div class="ml-5">
            <v-layout wrap>
                <user-form
                    ref="userForm"
                    :selected-item="selectedUser"
                    :is-show="showFormDialog"
                    :form-type="userFormType"
                    :roles="roles"
                    :paymentAgents="paymentAgents"
                    :isNotPaymentAgent="isNotPaymentAgent"
                    v-on:close-dialog="closeDialog"
                    v-on:save-user="saveUser"
                />
            </v-layout>
            <loading
                :is-show="loadingDialog"
                :is-done="loadingFinished"
                :is-html="isHtml"
                :message="loadingMessage"
                v-on:close-dialog="closeDialog"
            />
            <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
            <v-container class="container--fluid grid-list-md text-left" :style="{ fontSize: '15px', color: '#465a75' }">
                <v-icon :style="{ color: '#465a75', paddingBottom: '11px', marginRight: '5px' }" left-bottom x-large
                    >mdi-home</v-icon
                >
                User Accounts
            </v-container>
            <v-card v-card outlined :style="{ border: '0px solid #dedede', marginLeft: '10px', marginRight: '10px'}">
                <div class="d-flex flex-no-wrap justify-space-between" v-if="hasPermission('ROLE_TDM_VIEW_USER')">
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-row no-gutters>
                                <v-col cols="12" md="3" lg>
                                    <v-text-field
                                        class="ma-2"
                                        label="First Name"
                                        v-model="query.firstName"
                                        single-line
                                        outlined
                                        @change="search($event, 'firstName')"
                                        data-cy="module-firstname"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" lg>
                                    <v-text-field
                                        class="ma-2"
                                        label="Last Name"
                                        v-model="query.lastName"
                                        single-line
                                        outlined
                                        @change="search($event, 'lastName')"
                                        data-cy="module-lastname"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" lg>
                                    <v-text-field
                                        class="ma-2"
                                        label="Username"
                                        v-model="query.username"
                                        single-line
                                        outlined
                                        @change="search($event, 'username')"
                                        data-cy="module-username"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" lg>
                                    <v-text-field
                                        class="ma-2"
                                        label="Mobile Number"
                                        v-model="query.mobileNumber"
                                        single-line
                                        outlined
                                        @change="search($event, 'mobileNumber')"
                                        data-cy="module-mobile-number"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" lg>
                                    <v-menu
                                        v-model="fromDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        data-cy="date-menu-from"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                class="ma-2"
                                                single-line 
                                                outlined
                                                label="From"
                                                append-icon="mdi-calendar"
                                                readonly
                                                :value="fromDateDisp"
                                                v-on="on"
                                                data-cy="date-menu-from"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="en-in"
                                            v-model="query.dateFrom"
                                            no-title
                                            @input="fromDateMenu = false"
                                            :min="minDate"
                                            :max="fromMax"
                                            data-cy="date-menu-from"
                                        >
                                            <v-btn text color="primary" @click="clearFromDate()">Clear </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="3" lg>
                                    <v-menu
                                        ref="menu"
                                        v-model="toDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        data-cy="date-menu-to"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                class="ma-2"
                                                single-line 
                                                outlined
                                                label="To"
                                                append-icon="mdi-calendar"
                                                readonly
                                                :value="toDateDisp"
                                                v-on="on"
                                                data-cy="date-menu-to"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="en-in"
                                            v-model="query.dateTo"
                                            no-title
                                            @input="toDateMenu = false"
                                            :max="maxDate"
                                            :min="toMin"
                                            data-cy="date-menu-to"
                                        >
                                            <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear"
                                                >Clear
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="3" lg v-if="hasPermission('ROLE_TDM_VIEW_USER')">
                                    <v-btn
                                        :block="$vuetify.breakpoint.mdAndDown"
                                        class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }"
                                        @click="queryData(true)"
                                        data-cy="button-search"
                                    >
                                        <v-icon large :style="{ color: '#8eb0ca' }" left> mdi-magnify</v-icon> Search
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="3" lg>
                                    <v-btn class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }" @click="resetInputs()" data-cy="button-search">
                                    <v-icon large :style="{ color: '#8eb0ca' }" left> mdi-history </v-icon> Reset
                                </v-btn>
                                </v-col>
                                <v-col></v-col>
                                <v-col cols="12" md="3" lg v-if="hasPermission('ROLE_TDM_CREATE_USER')">
                                    <v-btn
                                        :block="$vuetify.breakpoint.mdAndDown"
                                        class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }"
                                        @click.stop="showUserForm('CREATED')"
                                        data-cy="button-create"
                                        >
                                        <v-icon large :style="{ color: '#8eb0ca' }" left> mdi-plus </v-icon>Create User
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!-- <v-flex xs12 sm6>
                            <v-text-field label="First Name" v-model="query.firstName" single-line outlined @change="search($event, 'firstName')" data-cy="module-firstname"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-text-field label="Last Name" v-model="query.lastName" single-line outlined @change="search($event, 'lastName')" data-cy="module-lastname"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-text-field label="Username" v-model="query.username" single-line outlined @change="search($event, 'username')" data-cy="module-username"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6>
                            <v-text-field label="Mobile Number" v-model="query.mobileNumber" single-line outlined @change="search($event, 'mobileNumber')" data-cy="module-mobile-number"></v-text-field>
                        </v-flex> -->
                            <!-- <v-col cols="12" md="5">
                                <v-menu
                                    v-model="fromDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    data-cy="date-menu-from"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            label="From"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            :value="fromDateDisp"
                                            v-on="on"
                                            data-cy="date-menu-from"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="en-in"
                                        v-model="query.dateFrom"
                                        no-title
                                        @input="fromDateMenu = false"
                                        :min="minDate"
                                        :max="fromMax"
                                        data-cy="date-menu-from"
                                    >
                                        <v-btn text color="primary" @click="clearFromDate()">Clear </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-menu
                                    ref="menu"
                                    v-model="toDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    data-cy="date-menu-to"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            label="To"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            :value="toDateDisp"
                                            v-on="on"
                                            data-cy="date-menu-to"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        locale="en-in"
                                        v-model="query.dateTo"
                                        no-title
                                        @input="toDateMenu = false"
                                        :max="maxDate"
                                        :min="toMin"
                                        data-cy="date-menu-to"
                                    >
                                        <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear"
                                            >Clear
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col> -->
                        </v-layout>
                    </v-container>
                </div>
            </v-card>

            <v-data-table
                :hide-default-header="true"
                :headers="headers"
                :items="displayUsers"
                :loading="loading"
                :page="page"
                :pageCount="numberOfPages"
                :options.sync="options"
                :server-items-length="totalUsers"
                :style="{ border: '1px solid #dedede', marginBottom: '20px', marginLeft: '10px', marginRight: '10px'}"
                class="mb-14"
                :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
            >
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text" :style="{ backgroundColor: '#dfedf8', fontWeight: 'bold', fontSize: '12px', color: 'black'}">
                                <span class="d-flex justify-center">{{ header.text }}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                            <td>
                                <span class="d-flex justify-center"> {{ item.username }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.firstName }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.lastName }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.dateCreated }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.isEnabled ? "ACTIVE" : "INACTIVE" }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center">
                                    <v-btn fab dark x-small color="#00a8e8" class="mr-2" @click.stop="viewUser(item)">
                                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab dark x-small color="#00a8e8" class="mr-2"
                                        @click.stop="showUserForm('UPDATE', item)"
                                        v-if="hasPermission('ROLE_TDM_UPDATE_USER')"
                                    >
                                        <v-icon dark medium>mdi-pencil</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                        Found no results.
                    </v-alert>
                </template>
            </v-data-table>
        </div>
    </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import UserForm from "@/views/components/UserForm.vue";
import DetailsForm from "@/views/components/DetailsForm.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    name: "Users",
    components: {
        Loading,
        UserForm,
        DetailsForm
    },
    data() {
        return {
            //filter/search variables
            filterQry: {
                qryName: ""
            },
            headers: [],
            paymentAgents: [],
            isNotPaymentAgent: false,
            displayUsers: [],
            remainderUsers: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            userFormType: "CREATE",
            selectedUser: {},
            roles: [],
            page: 1,
            totalUsers: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: "",
                item: {}
            },
            query: {
                firstName: "",
                lastName: "",
                username: "",
                mobileNumber: "",
                dateFrom: "",
                dateTo: ""
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        };
    },
    watch: {
        options: {
            handler() {
                console.log("1");
                this.queryData();
                this.setData();
            }
        },
        deep: true
    },
    computed: {
        //get role attributes
        ...mapGetters(["attributes", "user", "authorities"]),
        fromDateDisp() {
            this.toMin = moment().format(this.query.dateFrom);
            return this.query.dateFrom;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.query.dateTo);
            return this.query.dateTo;
        }
    },
    async mounted() {
        this.setHeaders();
        this.setDefaultDateRange();
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
            if (this.authorities.some(x => x.name === authority)) {
                return true;
            }
            return false;
        },
        async queryData(isSearch = false) {
            this.loading = true;
            if (isSearch) {
                this.options.page = 1;
            }
            const { page, itemsPerPage } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage;
                const userListResponse = await API.getUsers(pageNumber, limit, this.query);
                if (!userListResponse || userListResponse.error) {
                    //error getting data
                    console.log(`${userListResponse.error}`);
                } else {
                    var filteredList = userListResponse.users;
                    this.isNotPaymentAgent = userListResponse.isNotPaymentAgent;
                    if (page > 1 && this.remainderUsers.length > 0) {
                        filteredList = this.remainderUsers.concat(filteredList);
                    }
                    this.totalUsers = userListResponse.totalItems;
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage;
                    this.displayUsers = filteredList.slice(0, end);
                    this.remainderUsers = filteredList.length < itemsPerPage ? [] : filteredList.slice(end);
                    this.numberOfPages = userListResponse.pageCount;
                    // const hasMoreResponse = await API.getUsers(this.totalUsers, 1, this.query)
                    // if (!hasMoreResponse || hasMoreResponse.error) {
                    //     //error getting data
                    //     console.log(`${hasMoreResponse.error}`)
                    // } else {
                    //     var hasMoreList = hasMoreResponse.users
                    //     if(hasMoreList.length > 0) {
                    //       this.totalUsers = this.totalUsers + 1
                    //       this.numberOfPages = this.numberOfPages + 1
                    //     }
                    // }
                }
                this.loading = false;
            } catch (e) {
                console.log(e);
                this.loading = false;
            }
        },
        async setData() {
            try {
                const listResponse = await API.getAllRoles();
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`);
                } else {
                    this.roles = listResponse.roles;
                }
            } catch (e) {
                console.log(e);
            }
            try {
                const listResponse = await API.getAllPaymentAgents();
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`);
                } else {
                    this.paymentAgents = listResponse.paymentAgent;
                }
            } catch (e) {
                console.log(e);
            }
        },

        setHeaders() {
            var headers = [
                {
                    text: "Username",
                    value: "username"
                },
                {
                    text: "First Name",
                    value: "firstName"
                },
                {
                    text: "Last Name",
                    value: "lastName"
                },
                {
                    text: "Date Created",
                    value: "dateCreated"
                },
                {
                    text: "Status",
                    value: "isEnabled"
                },
                {
                    text: "Actions",
                    value: "controls",
                    sortable: false
                }
            ];
            this.headers = headers;
        },
        showUserForm(what, item = {}) {
            this.userFormType = what;
            this.selectedUser = item;
            this.showFormDialog = true;
        },
        closeDialog(type) {
            if (type === "details") {
                this.details.title = "";
                this.details.item = {};
                this.isShowDetails = false;
            } else if (type === "CREATED" || type === "UPDATE") {
                this.showFormDialog = false;
            } else if (type === "loading") {
                this.loadingDialog = false;
                // this.$router.go(0);
                this.queryData(this.isSearch);
            } else if (type === "loading-error") {
                this.loadingDialog = false;
            }
        },
        async saveUser(value) {
            this.loadingDialog = true;
            this.isHtml = true;
            this.loadingFinished = false;
            if (value) {
                if (value.formType === "CREATED") {
                    this.loadingMessage = `Creating new user <strong><i>${value.username}</i></strong>`;
                    setTimeout(async () => {
                        const createResponse = await API.createUser(value);
                        console.log(createResponse);
                        this.loadingFinished = true;
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false;
                            this.loadingMessage = createResponse.error;
                        } else {
                            this.loadingMessage = `Successfully created user <strong><i>${value.username}</i></strong>`;
                            this.showFormDialog = false;
                            this.$refs.userForm.resetForm();
                        }
                    }, 2000);
                } else if (value.formType === "UPDATE") {
                    this.loadingMessage = `Updating user <strong><i>${value.username}</i></strong>`;
                    setTimeout(async () => {
                        const updateResponse = await API.updateUser(value);
                        this.loadingFinished = true;
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false;
                            this.loadingMessage = updateResponse.error;
                        } else {
                            this.loadingMessage = `Successfully updated user <strong><i>${value.username}</i></strong>`;
                            console.log("SUCCESSFULLY UPDATED");
                            this.showFormDialog = false;
                            console.log("RESETTING FORM");
                            this.$refs.userForm.resetForm();
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command";
                    this.loadingFinished = true;
                }
            } else {
                this.loadingMessage = "Error processing data.";
                this.loadingFinished = true;
            }
        },
        viewUser(user) {
            console.log(user);
            this.details.title = user.username;
            this.details.item = user;
            this.isShowDetails = true;
        },
        search(event, type) {
            this.query[type] = event;
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD");
            return today;
        },
        setDefaultDateRange() {
            this.query.dateFrom = ""; //this.getToday();
            this.query.dateTo = "";
            this.maxDate = moment()
                .add(1, "days")
                .format("yyyy-MM-DD");
            this.fromMax = moment()
                .add(1, "days")
                .format("yyyy-MM-DD");
            this.toMin = moment().format(this.query.dateFrom);
        },
        clearToDate() {
            this.query.dateTo = "";
            this.toDateMenu = false;
        },
        clearFromDate() {
            this.query.dateFrom = "";
            this.fromDateMenu = false;
        },
        resetInputs() {
            this.query.firstName = ''
            this.query.lastName = ''
            this.query.username = ''
            this.query.mobileNumber = ''
            this.displayUsers = []
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
}
</style>
